<template>
  <v-container
    fluid
    grid-list-lg
    class="fill-height login_wrapper"
    :style="{ 'background-image': `url(${bgImage})` }"
  >
    <v-row justify="center" align="center" class="text-center">
      <v-col cols="12" md="5" lg="4">
        <v-card
          class="login_form_wrapper transparent white--text pa-5 elevation-24"
        >
          <v-container>
            <v-row>
              <v-col cols="12"> <h1>Welcome User</h1></v-col>
              <v-col cols="12">
                <v-form ref="login_form" class="login_form">
                  <v-text-field
                    label="Enter Your Email"
                    dark
                    filled
                    color="white"
                    v-model="loginData.email"
                    :rules="emailRules"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Enter Your Password"
                    dark
                    filled
                    color="white"
                    type="password"
                    v-model="loginData.password"
                    :rules="passwordRules"
                  >
                  </v-text-field>
                  <v-btn
                    color="success"
                    :loading="loading"
                    :disabled="loading"
                    block
                    @click.prevent="login"
                    >Login</v-btn
                  >
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      bgImage: require("@/assets/login_bg.jpg"),
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
      loginData: {
        email: "test@hp.com",
        password: "aaaaaa",
      },
    };
  },
  methods: {
    login() {
      if (this.$refs.login_form.validate()) {
        this.loading = true;
        var that = this;
        this.$store.commit('setIsFromLogin',true)
        this.$fb
          .auth()
          .signInWithEmailAndPassword(
            this.loginData.email,
            this.loginData.password
          )
          .then(() => {
            that.reset();
        //    this.$router.push('/dashboard')
          })
          .catch((err) => {
            that.loading = false;
            alert("login err : " + err.message);
          });
      }
    },
    reset() {
      this.loading = false;
      this.$refs.login_form.reset();
    },
  },
};
</script>

<style scoped>
.login_wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: brightness(21);
}
.login_form_wrapper {
  backdrop-filter: blur(21px);
  background-color: #0a0a0a2d !important;
}
</style>